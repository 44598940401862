export enum ContentType {
    Offer,
    Brand,
    Category,
}

export enum Category {
    TicketsAndAttractions = 15,
    GiftCards = 303,
}

export enum CommunitySecurityType {
    OpenRegistration = 1,
    CustomFieldValidation = 2,
    SSO = 3,
    ExternalIdValidationOptional = 4,
    ExternalIdValidationRequired = 5,
    RegistrationClosed = 6,
    SSOv2 = 7,
    SSOv3 = 8,
}

export enum entityTypes {
    community = 13,
}

export enum userRoles {
    communityRegistrationAdmin = 42,
}

export enum FeedType {
    AdditionsReactivations = 0,
    FullCensus = 1,
}

export enum ResultId {
    Success = 0,
    Failure = 1,
}

export enum Channel {
    Email = 1,
}

export enum ModalStage {
    FeedTypeSelect = 0,
    FileAttach = 1,
    HeaderMapper = 2,
    FilePreview = 3,
    FeedUpload = 4,
}
