import axios from 'axios';
import {
    CartProductQuantity,
    FooterContent,
    FirstPersonDataStatusResponse,
    LinkResponse,
    NewPerksFilterResults,
    OfferLinkResponse,
    RewardsBalanceAndCurrency,
    User,
    ValidateEmailRequest,
} from '../../types';
import { getAntiForgeryToken } from '.';

const baseUrl = new URL('/api/', window.location.origin);

const api = PscApiService();

interface BaseResult {
    resultCode: 'Failure' | 'Warning' | 'Success';
    resultMessage: string;
}

interface Result<T> extends BaseResult {
    data: T;
}

interface OffersResult<T> {
    offers: T;
}
export function getRewardsBalanceAndCurrency() {
    return api.get<Result<RewardsBalanceAndCurrency>>('credits/rewardcurrency');
}

export function getCartProductQuantity() {
    return api.get<Result<CartProductQuantity>>('shop/cart/products/quantity');
}

export function getUser() {
    return api.get<Result<User>>('users');
}

export function getNewPerksOffers() {
    return api.get<Result<NewPerksFilterResults>>('filters/25/offers');
}

export function getQuickSearchResults(query: string) {
    return api.get('search/quick-search', {
        params: {
            query: query,
            useFuzzyQuickSearch: true,
        },
    });
}

export function getMostPopularSavings() {
    return api.get<Result<OffersResult<OfferLinkResponse[]>>>(`filters/127/offers`);
}

export function getRecentlyViewed() {
    return api.get<Result<OfferLinkResponse[]>>('content/offers/recent');
}

export function getMostPopularCategories() {
    return api.get<Result<LinkResponse[]>>('content/categories');
}

export function sendLoginLinkEmail(email: string) {
    return api.post<BaseResult>('nli/send-login-link-email', email);
}

export function validateEmail(email: string, communityId: number, redirectUri?: string) {
    const validateEmail = { email, communityId, redirectUri } as ValidateEmailRequest;
    return api.post('nli/validate-email-login', validateEmail);
}

export function getFirstPersonDataStatus() {
    return api.get<FirstPersonDataStatusResponse>('first-person-data/status');
}

export function saveEmailChange(email: string) {
    return api.put(`users/email?newEmail=${email}`);
}

export function getFooterContent() {
    return api.get<Result<FooterContent>>('content/footer');
}

export function acceptCookies() {
    return api.post('users/cookies/consent?consent=true');
}

export function optOutOfFirstPersonDataEmailCollection() {
    return api.post(`first-person-data/email/opt-out`);
}

export function suppressFirstPersonDataEmailModal() {
    return api.post('first-person-data/email/suppress');
}

export function optIntoFirstPersonDataEmailCollection() {
    return api.post('first-person-data/email/opt-in');
}

export function changeCommunity(communityId: number) {
    return api.post(`users/change-community/${communityId}`);
}

export function getAdminUtilities() {
    return api.get('admin/utilities');
}

export function replaceCommunityTokens(text: string) {
    return api.get<string>(`communitycontent?input=${text}`);
}

export function getAudiences() {
    return api.get('/subscriptions/audiences');
}

export function getAudienceSuppressions(communityId: number) {
    return api.get(`/audience-suppressions/${communityId}`);
}

export function adminChangeCommunity(communityId: number) {
    return api.post(`users/admin/change-community/${communityId}`);
}

export function getCommunities(community: string) {
    return api.get<any>('/nli/communities?query=' + community);
}

export function getInterests() {
    return api.get(`users/interests`);
}

export function addInterest(interestId: number) {
    return api.post(`users/interests/${interestId}`);
}

export function removeInterest(interestId: number) {
    return api.delete(`users/interests/${interestId}`);
}

export function clearInterests() {
    return api.delete(`users/interests/`);
}

export function postFile(formData: FormData) {
    return axios({
        method: 'POST',
        url: '/api/blob/container/file',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function recordUserConsent() {
    return axios({
        url: '/record-user-consent',
        method: 'post',
        headers: {
            RequestVerificationToken: getAntiForgeryToken(),
        },
        params: {
            redirectUri: new URLSearchParams(window.location.search).get('redirectUri'),
        },
        withCredentials: true,
    });
}

export function getBrowserXPopupEligibility() {
    return api.get<boolean>(`browserx/eligible`);
}

export function dismissBrowserXPopup() {
    return api.post<void>('browserx/dismiss');
}

export function installBrowserXPopup() {
    return api.post<void>('browserx/install');
}

function PscApiService() {
    const instance = axios.create({
        baseURL: baseUrl.href,
        timeout: 30000,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    instance.interceptors.request.use(
        config => {
            if (!config.method) {
                return config;
            }

            if (['post', 'put', 'delete'].includes(config.method)) {
                if (config.headers) {
                    config.headers['RequestVerificationToken'] = getAntiForgeryToken();
                }
            }

            return config;
        },
        err => Promise.reject(err),
    );

    return instance;
}
